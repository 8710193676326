<template>
  <div class="modal fade" tabindex="-1" id="modal_photo" @click.self="close()">
    <div class="modal-dialog modal-lg">
      <div class="modal-content modal-lg">
        <div class="modal-body">
          <CameraCapture v-if="openModal && !photo && !selectedFile" @photo-captured="handlePhotoCaptured" />
          <div v-else>
            <img class="img-fluid" :src="url(photo)" v-if="photo" alt="Foto Capturada" style="width: 100%;"/>
            <button class="btn btn-primary mt-2" @click="retakePhoto">Tirar Novamente</button>
          </div>
        </div>
        <input type="file" class="btn ms-2" @change="handleFileChange" accept="image/*" />
        <div class="modal-footer">
          <button type="button" class="btn btn-light" @click="close">Fechar</button>
          <button v-if="photo && initialPhoto" type="button" class="btn btn-danger" @click="clearPhoto">Excluir Foto</button>
          <button v-if="photo || selectedFile" type="button" class="btn btn-primary" @click="savePhoto">Salvar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CameraCapture from './CameraCapture.vue';
import str from "../helpers/str";

export default {
  name: 'ModalPhoto',
  emits: ['onSaveClick', 'onClose', 'onClearClick'],
  components: {
    CameraCapture,
  },
  props: {
    openModal: {
      type: Boolean,
      required: true
    },
    initialPhoto: null,
  },
  data() {
    return {
      photo: null,
      selectedFile: null,
    }
  },
  watch: {
    openModal(newValue) {
      if (newValue) {
        this.photo = this.initialPhoto;
        this.selectedFile = null;
        this.toggleModal();
      }
    }
  },
  methods: {
      url(path) {
          return str.url(path)
      },
    handlePhotoCaptured(photo) {
      this.photo = photo;
      this.selectedFile = null;
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.selectedFile = file;
        this.loadAndResizeImage();
      }
    },
    async loadAndResizeImage() {
      const image = new Image();
      const reader = new FileReader();

      reader.onload = (e) => {
        image.onload = () => {
          const canvas = document.createElement('canvas');
          const context = canvas.getContext('2d');

          const maxWidth = 320;
          const maxHeight = 240;

          let width = image.width;
          let height = image.height;

          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }

          canvas.width = width;
          canvas.height = height;

          context.drawImage(image, 0, 0, width, height);
          this.photo = canvas.toDataURL('image/png');
        };

        image.src = e.target.result;
      };

      reader.readAsDataURL(this.selectedFile);
    },
    savePhoto() {
      this.$emit('onSaveClick', this.photo);
      this.toggleModal();
    },
    close() {
      this.$emit('onClose');
      this.toggleModal();
    },
    toggleModal() {
      let modal = bootstrap.Modal.getOrCreateInstance(document.querySelector('#modal_photo'));
      modal.toggle();
    },
    retakePhoto() {
      this.photo = null;
      this.selectedFile = null;
    },
    clearPhoto() {
      Swal.fire({
        title: 'Tem certeza que deseja excluir a foto?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, excluir a foto.',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: 'btn btn-secondary'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          this.photo = null;
          this.$emit('onClearClick', this.photo);
          this.toggleModal();
        }
      });
    },
  },
};
</script>

<style>
</style>
