<template>
  <div class="card card-flush h-xl-100" :style="schedulesToday.length > 1 ? styles : ''">
    <div class="card-header ps-2 border-0 pt-5">
        <div class="row">
            <div class="col-md-10">
              <h3 class="card-title align-items-start flex-column">
                  <span class="card-label fw-bolder text-dark">Pacientes hoje</span>
                  <span class="text-muted mt-1 fw-bold fs-7">Confira a lista abaixo</span>
              </h3>
            </div>
            <div class="col-md-1">
                <button class="btn btn-primary btn-sm mt-4" title="Lista de pacientes"
                        style="padding: calc(0.55rem + 1px) calc(0.50rem + 1px);"
                        @click="printSchedules">
                    Baixar
                </button>
            </div>
        </div>
    </div>
    <div class="card-body" style="padding: 2rem 0.85rem;">
      <div v-if="schedulesToday.length <= 0" class="text-center mt-10">
        <img :src="require('@/assets/images/no_datal.svg')"
             alt="Nenhum paciente hoje" class="w-50 align-self-center"/>
        <p class="h4 mt-3">Nenhum paciente para hoje</p>

        <hr class="d-none d-lg-inline">
      </div>
      <div
          class="d-flex align-items-sm-center mb-7 border-gray-300 border-dashed rounded p-1 border-hover-primary bg-hover-light-primary"
          style="cursor: pointer;" @click.stop="handleClick(schedule)"
          v-for="schedule in schedulesToday" :key="schedule.id">
        <div class="symbol symbol-50px me-5" @click.stop="takePicture(schedule)" v-if="innerWidth > 1100">
              <span class="symbol-label">
                <img :src="url(schedule.patient_photo)" v-if="schedule.patient_photo"
                     alt="Foto do patiente" class="h-100 w-100 align-self-center"  >
                <img :src="require('@/assets/images/no-photo.png')"
                     alt="Foto do patiente" class="h-50 align-self-center" v-else>
              </span>
        </div>
        <div class="d-flex align-items-center flex-row-fluid flex-wrap ps-2">
          <div class="flex-grow-1 me-2">
            <span class="text-gray-800 text-hover-primary fs-6 fw-bolder mb-2 d-block text-truncate" style="cursor: pointer; max-width: 220px;">
              {{ schedule.patient_name }}
            </span>
            <span v-if="schedule?.sched_status ==='CO' && !schedule?.sched_start && !schedule?.sched_arrive"
              class="badge badge-light-primary fw-bolder">
              Consulta confirmada
            </span>
            <span v-if="schedule?.sched_arrive && !schedule?.sched_start && schedule?.sched_status !=='FI'"
                  class="badge badge-warning fw-bolder">
              Aguardando Atendimento
            </span>
            <span v-if="schedule?.sched_start && schedule?.sched_status !== 'FI'"
                  class="badge badge-sm badge-light-success fw-bolder">
              Em atendimento
            </span>
            <span v-if="schedule?.sched_status === 'FI'"
                  class="badge badge-sm badge-light-info fw-bolder">
              Consulta finalizada
            </span>
            <span class="text-muted fw-bold d-block fs-7 mt-2">
                {{ schedule.patient_phone ? maskIt(schedule.patient_phone, '(##) #####-####') : schedule.patient_cpf }}
            </span>
            <span class="text-muted fw-bold d-block fs-7 text-truncate" style="max-width: 220px;">
                {{ schedule.insurance_name }}
            </span>
            <span class="badge badge-primary fw-bolder my-2" title="Hora de agendamento">
                {{ schedule.sched_time }}
            </span>
            <span class="badge badge-success fw-bolder my-2 mx-1" title="Hora de chegada" v-if="schedule.sched_arrive">
                {{ date(schedule.sched_arrive).format('HH:mm') }}
            </span>
            <span class="badge badge-warning fw-bolder my-2 mx-1" title="Hora de início do atendimento"
                  v-if="schedule.sched_start && schedule?.sched_status !== 'CO'  && schedule?.sched_status !== 'AG'">
                {{ date(schedule.sched_arrive).format('HH:mm') }}
            </span>
            <span class="badge badge-info fw-bolder my-2 mx-1" title="Hora de término"
                  v-if="schedule.sched_end">
                {{ date(schedule.sched_end).format('HH:mm') }}
            </span>
          </div>
          <div class="text-right me-5">
              <span>
                  <BtnArriveSchedule
                      :classes="schedule?.sched_arrive ? 'bi-calendar-x btn-warning' : 'bi-calendar-check btn-success'"
                      :scheduleEdit="schedule"
                      @onArriveSchedule="arriveSchedule"
                  />
              </span>
              <span>
                  <BtnFinishOrReOpenSchedule
                      :classes="'bi bi-check2-square btn-sm ' + (schedule?.sched_status === 'FI' ? 'btn-outline btn-outline-info' : 'btn-info')"
                      :scheduleEdit="schedule"
                      @onFinishSchedule="$emit('onFinishSchedule');"
                      @onReopenSchedule="$emit('onReopenSchedule');"
                  />
              </span>
              <span>
                  <BtnStartAttendanceSchedule
                      :classes="schedule.sched_start ? 'btn-sm bi bi-arrow-clockwise' : 'btn-sm bi bi-play-fill'"
                      :scheduleEdit="schedule"
                      @onStartAttendance="$emit('onStartAttendance');"
                  />
              </span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ModalPhoto
      :openModal="openModalPhoto"
      :initialPhoto="scheduleSelected?.patient_photo ? scheduleSelected?.patient_photo : null"
      @onSaveClick="savePhoto"
      @onClearClick="savePhoto"
      @onClose="openModalPhoto=false;"
  />

</template>

<script>
import http from "../../helpers/http";
import strMask from "../../helpers/strMask";
import Alert from "../../helpers/alert";
import ModalPhoto from "../../components/ModalPhoto.vue";
import BtnFinishOrReOpenSchedule from "./BtnFinishOrReOpenSchedule";
import BtnStartAttendanceSchedule from "./BtnStartAttendanceSchedule";
import BtnArriveSchedule from "./BtnArriveSchedule.vue";
import str from "../../helpers/str";

export default {
  name: "CalendarSchedulesToday",
  props: ['idProf', 'idSpec', 'uniqueKey', 'styles'],
  emits: ['onClick', 'onArriveSchedule', 'onFinishSchedule', 'onReopenSchedule', 'onStartAttendance', 'onPhotoSaved'],
  components: {BtnArriveSchedule, ModalPhoto, BtnFinishOrReOpenSchedule, BtnStartAttendanceSchedule},
  data() {
    return {
      schedulesToday: [],
      scheduleSelected: null,
      openModalPhoto: false,
    }
  },
  computed: {
    place() {
      return this.$store.state.auth.place;
    },
    userAuth() {
      return this.$store.state.auth.user;
    },
  },
  mounted() {
    this.loadSchedules();
    this.innerWidth = window.innerWidth;
  },
  watch: {
    idProf() {
      this.loadSchedules();
    },
    uniqueKey() {
      this.loadSchedules();
    },
  },
  methods: {
    date: moment,
      url(path) {
          return str.url(path)
      },
    maskIt(value, mask) {
      return strMask.it(value, mask);
    },
    arriveSchedule(idSched, isSchedArrive) {
        this.$emit('onArriveSchedule', idSched, isSchedArrive);
    },
    handleClick(schedule) {
      this.$emit('onClick', schedule);
    },
    loadSchedules() {
      if (!this.idProf) {
        this.schedulesToday = [];
        return;
      }

      return http.get(`/places/${this.place.id_place}/schedules/today`, {
        params: {
          id_prof: this.idProf,
          id_spec: this.idSpec,
          base: 'TO',
        }
      }).then((response) => {
        this.schedulesToday = response.data.filter((record) => {
          return ['AG', 'CO', 'FI', 'AR'].includes(record.sched_status);
        });
        return response;
      });
    },
    printSchedules() {
      if (!this.idProf) {
        Alert.toastwarning('Selecione um profissional para imprimir a agenda.');
        return;
      }

      let html = `
      <div class="text-start">
      <label for="start-date">Data de início:</label>
      <input type="date" id="start-date" class="form-control" required placeholder="Data de início" value="${new Date().toISOString().split('T')[0]}">
      <label for="end-date">Data de fim:</label>
      <input type="date" id="end-date" class="form-control" required placeholder="Data de término" value="${new Date().toISOString().split('T')[0]}">
      <br>
      <label for="pdf-type">Agrupar por:</label>
      <select id="pdf-type" class="form-control" required>
        <option value="ESPEC" selected>Especialidade</option>
        <option value="PROF">Profissional</option>
      </select>
      </div>
      `;

      Swal.fire({
        title: 'Selecione o intervalo de datas',
        html: html,
        heightAuto: false,
        showCancelButton: true,
        confirmButtonText: 'Imprimir',
        confirmButtonColor: '#780f5a',
        cancelButtonText: 'Fechar',
        focusConfirm: false,
        preConfirm: () => {
          const startDate = document.getElementById('start-date').value;
          const endDate = document.getElementById('end-date').value;
          const groupBy = document.getElementById('pdf-type').value;
          if (!startDate || !endDate) {
            Swal.showValidationMessage('Por favor, selecione ambas as datas.');
            return null;
          }
          return {startDate, endDate, groupBy};
        }
      }).then((result) => {
        if (result.isConfirmed) {
          const {startDate, endDate, groupBy} = result.value;

          http.get(`/places/${this.place.id_place}/schedules/download-pdf`, {
            params: {
              id_prof: this.idProf,
              id_spec: this.idSpec,
              start_date: startDate,
              end_date: endDate,
              base: 'AL',
              group_by: groupBy,
            },
            responseType: 'blob'
          }).then(response => {
            const file = new Blob([response.data], {type: 'application/pdf'});
            const fileURL = URL.createObjectURL(file);

            const iframe = document.createElement('iframe');
            iframe.style.display = 'none';
            iframe.src = fileURL;

            document.body.appendChild(iframe);

            iframe.onload = function () {
              iframe.contentWindow.print();
            };

            URL.revokeObjectURL(fileURL);
          }).catch(error => {
            console.error("Download error:", error);
          });
        }
      });
    },
    takePicture(schedule) {
      this.scheduleSelected = schedule;
      this.openModalPhoto = true;
    },
    savePhoto(photo) {
        this.openModalPhoto = false;

        const formData = new FormData();
        formData.append('_method', 'put');
        if (photo)
            formData.append('patient_photo', str.convertFileToBase64(photo.split(',')[1]));

        http({
            method: 'post',
            url: `/places/${this.place.id_place}/patients/${this.scheduleSelected.id_patient}`,
            data: formData
        }).then((_) => {
            this.scheduleSelected = null;
            this.$emit('onPhotoSaved');
            Alert.toastSuccess('Foto armazenada com sucesso!')
        }).catch((e) => {
            Alert.toastError(e.response.data);
        });
    }
  },
}
</script>

<style>
.swal2-container .swal2-html-container {
  max-height: 100% !important;
}
</style>