<template>

  <div v-if="!hasPermission('List Professional')">Você não tem permissão para acessar esta página!</div>
  <div v-else>
      <Toolbar />
    <div class="container-xxl">
      <div class="card w-100">
        <div class="card-header">
          <div class="card-title">
            <div class="d-flex align-items-center position-relative my-1">
          <span class="svg-icon svg-icon-1 position-absolute ms-6">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1"
                    transform="rotate(45 17.0365 15.1223)" fill="black"></rect>
              <path
                  d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                  fill="black"></path>
            </svg>
          </span>
              <input type="text" v-model="search"
                     class="form-control form-control-solid w-250px ps-14"
                     placeholder="Pesquisar ...">
            </div>
          </div>
          <div class="card-toolbar">
            <div class="col-md-6">
              <button class="btn btn-flex btn-success m-1" @click="onClickBtnInvite">
                <i class="bi bi-plus-lg"></i> Convidar
              </button>
            </div>
            <div class="col-md-6">
              <button v-if="hasPermission('Store Professional')" class="btn btn-flex btn-primary m-1"
                      @click="onClickBtnStore">
                <i class="bi bi-plus-lg"></i> Adicionar
              </button>
            </div>
          </div>
        </div>
        <div class="card-body py-4">

          <ul class="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold">
            <li class="nav-item">
              <a class="nav-link active" aria-current="page" href="#" id="tab-nav-professionals"
                 data-bs-toggle="tab" data-bs-target="#nav-professionals">Profissionais</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#" id="tab-nav-invites"
                 data-bs-toggle="tab" data-bs-target="#nav-invites">Convites</a>
            </li>
          </ul>

          <hr>

          <div class="tab-content">
            <div class="tab-pane fade show active" id="nav-professionals" role="tabpanel"
                 aria-labelledby="nav-professionals-tab">
              <div class="table-responsive">
                <table class="table table-row-bordered gy-5">
                  <thead>
                  <tr class="fw-bold fs-6 text-muted">
                    <th>Nome</th>
                    <th>E-mail</th>
                    <th>CPF</th>
                    <th>Ativo</th>
                    <th>Ações</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="professional in professionalsFiltered">
                    <td>{{ professional.prof_name }}</td>
                    <td>{{ professional.prof_email }}</td>
                    <td>{{ professional.prof_cpf }}</td>
                    <td>
                      <span class="badge badge-success"
                            v-if="professional.active_prof_place && professional.active">Sim</span>
                      <span class="badge badge-danger" v-else>Não</span>
                    </td>
                    <td>
                      <div class="btn-group">
                        <button class="btn btn-icon btn-bg-light btn-active-color-primary me-1"
                                v-if="hasPermission('Update Professional')"
                                @click="onClickBtnSettings(professional)"
                                data-bs-toggle="tooltip" title="Configurações do profissional">
                          <i class="bi bi-gear"></i>
                        </button>
                        <button class="btn btn-icon btn-bg-light btn-active-color-primary me-1"
                                v-if="hasPermission('Update Professional') && (professional.id_place_owner === this.place.id_place || professional.id_prof === userAuth.id_prof)"
                                @click="onClickBtnEdit(professional)" data-bs-toggle="tooltip"
                                title="Editar profissional">
                          <i class="bi bi-pen"></i>
                        </button>

                        <!-- TODO - Somente liberar após criação de método de validação de documentação para a nova especialidade -->
                        <button class="btn btn-icon btn-bg-light btn-active-color-primary me-1"
                                v-if="hasPermission('Update Professional')  && (professional.id_place_owner === this.place.id_place || professional.id_prof === userAuth.id_prof)"
                                @click="onClickBtnSpec(professional)" data-bs-toggle="tooltip" title="Especialidades">
                          <i class="bi bi-mortarboard"></i>
                        </button>
                        <button class="btn btn-icon btn-bg-light btn-active-color-primary me-1"
                                v-if="true || hasPermission('Prof Place Paymethod')"
                                @click="onClickBtnPaymethod(professional)" title="Formas de pagamento">
                          <i class="bi bi-cash-coin"></i>
                        </button>
                        <button class="btn btn-icon btn-bg-light btn-active-color-primary me-1"
                                v-if="true || hasPermission('Prof Place Insurance')"
                                @click="onClickBtnInsurance(professional)" title="Convênios">
                          <i class="bi bi-hospital"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="tab-pane fade" id="nav-invites" role="tabpanel" aria-labelledby="nav-invites-tab">
              <InviteProfessional :searchParam="search" @onSubmit="loadProfessionals"/>
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade" tabindex="-1" id="modal_store">
        <div class="modal-dialog modal-lg">
          <form method="post" @submit.prevent="submitFormStore">
            <div class="modal-content modal-lg">
              <div class="modal-header">
                <h5 class="modal-title">Adicionar Profissional</h5>
                <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal"
                     aria-label="Fechar">
                  <i class="bi bi-x-lg"></i>
                </div>
              </div>
              <div class="modal-body">
                <div class="row">

                  <div class="col-md-12 mb-6 mt-4">
                    <label for="prof_name" class="required form-label required">Nome</label>
                    <input class="form-control form-control-solid"
                           v-model="formStore.prof_name" id="prof_name" required>
                    <small class="text-danger" v-if="formStoreErrors.prof_name">
                      {{ formStoreErrors.prof_name.join('\n') }}
                    </small>
                  </div>

                  <div class="col-md-12 mb-6 mt-4">
                    <label for="prof_email" class="required form-label required">E-mail</label>
                    <input class="form-control form-control-solid"
                           v-model="formStore.prof_email" id="prof_email" required>
                    <small class="text-danger" v-if="formStoreErrors.prof_email">
                      {{ formStoreErrors.prof_email.join('\n') }}
                    </small>
                  </div>

                  <div class="col-md-12 mb-6 mt-4">
                    <label for="prof_cpf" class="required form-label required">
                      CPF
                    </label>
                    <input class="form-control form-control-solid"
                           v-mask="'###.###.###-##'" placeholder="999.999.999-99"
                           v-model="formStore.prof_cpf" id="prof_cpf">
                    <small class="text-danger" v-if="formStoreErrors.prof_cpf">
                      {{ formStoreErrors.prof_cpf.join('\n') }}
                    </small>
                  </div>

                  <div class="col-md-12 mb-6 mt-4">
                    <label for="prof_phone" class="required form-label required">
                      Telefone
                    </label>
                    <input class="form-control form-control-solid"
                           v-mask="['(##) ####-####', '(##) #####-####']"
                           placeholder="(99) 9 9999-9999"
                           v-model="formStore.prof_phone" id="prof_phone" required>
                    <small class="text-danger" v-if="formStoreErrors.prof_phone">
                      {{ formStoreErrors.prof_phone.join('\n') }}
                    </small>
                  </div>

                  <!-- <div class="col-md-12 ms-4 mb-6 mt-4">
                     <div class="row form-check form-switch">
                       <label class="form-check-label" for="active">Ativo?</label>
                       <input class="form-check-input" type="checkbox" role="switch" id="active"
                              v-model="formStore.active">
                     </div>
                   </div> -->

                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-light" data-bs-dismiss="modal">Fechar</button>
                <button type="submit" class="btn btn-primary">Cadastrar</button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div class="modal fade" tabindex="-1" id="modal_edit">
        <div class="modal-dialog modal-lg">
          <form method="post" @submit.prevent="submitFormEdit">
            <div class="modal-content modal-lg">
              <div class="modal-header">
                <h5 class="modal-title">Editar profissional</h5>
                <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal"
                     aria-label="Fechar">
                  <i class="bi bi-x-lg"></i>
                </div>
              </div>
              <div class="modal-body">
                <div class="row">

                  <div class="col-md-12 mb-6 mt-4">
                    <label for="prof_name" class="required form-label required">Nome</label>
                    <input class="form-control form-control-solid"
                           v-model="formEdit.prof_name" id="prof_name" required>
                    <small class="text-danger" v-if="formEditErrors.prof_name">
                      {{ formEditErrors.prof_name.join('\n') }}
                    </small>
                  </div>

                  <div class="col-md-12 mb-6 mt-4">
                    <label for="prof_email" class="required form-label required">E-mail</label>
                    <input class="form-control form-control-solid"
                           v-model="formEdit.prof_email" id="prof_email" required disabled>
                    <small class="text-danger" v-if="formEditErrors.prof_email">
                      {{ formEditErrors.prof_email.join('\n') }}
                    </small>
                  </div>

                  <div class="col-md-12 mb-6 mt-4">
                    <label for="prof_cpf" class="required form-label required">
                      CPF
                    </label>
                    <input class="form-control form-control-solid"
                           v-mask="'###.###.###-##'" placeholder="999.999.999-99"
                           v-model="formEdit.prof_cpf" id="prof_cpf" required>
                    <small class="text-danger" v-if="formEditErrors.prof_cpf">
                      {{ formEditErrors.prof_cpf.join('\n') }}
                    </small>
                  </div>

                  <div class="col-md-12 mb-6 mt-4">
                    <label for="prof_phone" class="required form-label required">
                      Telefone
                    </label>
                    <input class="form-control form-control-solid"
                           v-mask="['(##) ####-####', '(##) #####-####']"
                           placeholder="(99) 9 9999-9999"
                           v-model="formEdit.prof_phone" id="prof_phone" required>
                    <small class="text-danger" v-if="formEditErrors.prof_phone">
                      {{ formEditErrors.prof_phone.join('\n') }}
                    </small>
                  </div>

                  <!-- <div class="col-md-12 ms-4 mb-6 mt-4">
                    <div class="row form-check form-switch">
                      <label class="form-check-label" for="active">Ativo?</label>
                      <input class="form-check-input" type="checkbox" role="switch" id="active"
                             v-model="formEdit.active">
                    </div>
                  </div> -->

                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-light" data-bs-dismiss="modal">Fechar</button>
                <button type="submit" class="btn btn-primary">Salvar</button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div class="modal fade" tabindex="-1" id="modal_settings">
        <div class="modal-dialog modal-lg">
          <form method="post" @submit.prevent="submitFormSettings">
            <div class="modal-content modal-lg">
              <div class="modal-header">
                <h5 class="modal-title">Configurações</h5>
                <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal"
                     aria-label="Fechar">
                  <i class="bi bi-x-lg"></i>
                </div>
              </div>
              <div class="row ms-6">
                <ul class="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold">
                  <li class="nav-item">
                    <a class="nav-link active" aria-current="page" href="#" id="tab-nav-settings-global"
                       data-bs-toggle="tab" data-bs-target="#nav-settings-global">Globais</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="#" id="tab-nav-settings-spec" data-bs-toggle="tab"
                       data-bs-target="#nav-settings-spec">Por especialidade</a>
                  </li>
                </ul>
              </div>
              <div class="modal-body">

                <div class="tab-content">

                  <div class="tab-pane fade show active" id="nav-settings-global" role="tabpanel"
                       aria-labelledby="nav-settings-global-tab">
                    <div class="row mt-4">
                      <div class="col-md-6">
                        <div class="mb-10">
                          <label for="default_release_time_for" class="required form-label">
                            Liberação de horário padrão
                          </label>
                          <select class="form-control form-control-solid" id="default_release_time_for"
                                  v-model="formSettings.default_release_time_for">
                            <option value="B">Online e presencial</option>
                            <option value="O">Online</option>
                            <option value="F">Presencial</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="mb-10">
                          <label for="interval_minutes" class="required form-label">
                        <span>
                          Tempo médio de atendimento <small>(Em minutos)</small>
                        </span>
                          </label>
                          <input type="number" placeholder="Ex. 30" v-model="formSettings.interval_minutes"
                                 class="form-control form-control-solid">
                        </div>
                      </div>
                      <div class="row col-md-12">
                        <div class="col-md-6">
                          <div class="mb-10">
                            <label for="time_begin" class="required form-label">Início de atendimento</label>
                            <input type="text" v-mask="'##:##'" placeholder="08:00" v-model="formSettings.time_begin"
                                   class="form-control form-control-solid">
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="mb-10">
                            <label for="time_end" class="required form-label">Fim do atendimento</label>
                            <input type="text" v-mask="'##:##'" placeholder="12:00" v-model="formSettings.time_end"
                                   class="form-control form-control-solid">
                          </div>
                        </div>
                      </div>
                      <div class="row col-md-12">
                        <div class="col-md-6">
                          <div class="mb-10">
                            <label for="min_patient_age" class="form-label">Idade minima de atendimento <small>(em
                              anos)</small> </label>
                            <input type="number" placeholder="Ex. 0" v-model="formSettings.min_patient_age"
                                   class="form-control form-control-solid">
                            <small>(não preencher se não houver restrição)</small>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="mb-10">
                            <label for="max_patient_age" class="form-label">Idade máxima de atendimento" <small>(em
                              anos)</small> </label>
                            <input type="number" placeholder="Ex. 120" v-model="formSettings.max_patient_age"
                                   class="form-control form-control-solid">
                            <small>(não preencher se não houver restrição)</small>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="row form-check form-switch">
                          <label class="form-check-label" for="show_phone">Mostrar telefone no site?</label>
                          <input class="form-check-input" type="checkbox" role="switch" id="show_phone"
                                 v-model="formSettings.show_phone">
                        </div>
                      </div>
                      <div class="col-md-12 mt-3">
                        <div class="row form-check form-switch">
                          <label class="form-check-label" for="show_address">Mostrar endereço no site?</label>
                          <input class="form-check-input" type="checkbox" role="switch" id="show_address"
                                 v-model="formSettings.show_address">
                        </div>
                      </div>
                      <div class="col-md-12 mt-3">
                        <div class="row form-check form-switch">
                          <label class="form-check-label" for="notify_on_new_schedule">Deseja receber uma notificação
                            para cada novo <agendamento></agendamento>?</label>
                          <input class="form-check-input" type="checkbox" role="switch" id="notify_on_new_schedule"
                                 v-model="formSettings.notify_on_new_schedule">
                        </div>
                      </div>
                      <div class="col-md-12 mt-3">
                        <div class="row form-check form-switch">
                          <label class="form-check-label" for="available_to_patient">Disponível para agendamento pelo
                            paciente?</label>
                          <input class="form-check-input" type="checkbox" role="switch" id="available_to_patient"
                                 v-model="formSettings.available_to_patient">
                        </div>
                      </div>
                      <div class="col-md-12 mt-3">
                        <div class="row form-check form-switch">
                          <label class="form-check-label" for="active">Ativo?</label>
                          <input class="form-check-input" type="checkbox" role="switch" id="active"
                                 v-model="formSettings.active_prof_place">
                        </div>
                      </div>
                      <div class="modal-footer">
                        <button type="button" class="btn btn-light" data-bs-dismiss="modal">Fechar</button>
                        <button type="submit" class="btn btn-primary">Salvar</button>
                      </div>
                    </div>
                  </div>

                  <div class="tab-pane fade" id="nav-settings-spec" role="tabpanel"
                       aria-labelledby="nav-settings-spec-tab">
                    <div v-for="(specialty, index) in newSpecialties">
                      <div class="card card-xxl-stretch mb-5 mb-xxl-10 card-bordered shadow">
                        <div class="card-header">
                          <div class="card-title">
                            <h3>{{ specialty.spec_name }}</h3>
                          </div>
                        </div>
                        <div class="card-body">
                          <div class="col-md-6">
                            <div class="mb-10">
                              <label for="interval_minutes" class="required form-label">
                        <span>
                          Tempo médio de atendimento <small>(Em minutos)</small>
                        </span>
                              </label>
                              <input type="number" placeholder="Ex. 30" v-model="specialty.interval_minutes"
                                     class="form-control form-control-solid">
                              <small>(não preencher se para usar configuração global)</small>
                            </div>
                          </div>
                          <div class="row col-md-12">
                            <div class="col-md-6">
                              <div class="mb-10">
                                <label for="min_patient_age" class="form-label">Idade minima de atendimento <small>(em
                                  anos)</small> </label>
                                <input type="number" placeholder="Ex. 0" v-model="specialty.min_patient_age"
                                       class="form-control form-control-solid">
                                <small>(não preencher se para usar configuração global)</small>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="mb-10">
                                <label for="max_patient_age" class="form-label">Idade máxima de atendimento" <small>(em
                                  anos)</small> </label>
                                <input type="number" placeholder="Ex. 120" v-model="specialty.max_patient_age"
                                       class="form-control form-control-solid">
                                <small>(não preencher se para usar configuração global)</small>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <fieldset class="border p-3 rounded">
                              <legend class="w-auto px-2 fs-6 fw-bold mb-4">Disponível para agendamento pelo paciente?</legend>

                              <div class="form-check form-check-inline">
                                <input
                                    class="form-check-input"
                                    type="radio"
                                    id="spec_available_to_patient_global"
                                    :value="null"
                                    v-model="specialty.available_to_patient"
                                />
                                <label for="spec_available_to_patient_global" class="form-check-label">Usar configuração global</label>
                              </div>
                              <div class="form-check form-check-inline">
                                <input
                                    class="form-check-input"
                                    type="radio"
                                    id="spec_available_to_patient_true"
                                    value="true"
                                    v-model="specialty.available_to_patient"
                                />
                                <label for="spec_available_to_patient_true" class="form-check-label">Disponível</label>
                              </div>
                              <div class="form-check form-check-inline">
                                <input
                                    class="form-check-input"
                                    type="radio"
                                    id="spec_available_to_patient_false"
                                    value="false"
                                    v-model="specialty.available_to_patient"
                                />
                                <label for="spec_available_to_patient_false" class="form-check-label">Indisponível</label>
                              </div>
                            </fieldset>
                          </div>
                        </div>
                        <div class="card-footer"
                             v-if="JSON.stringify(specialties[index]) !== JSON.stringify(newSpecialties[index])">
                          <button type="button" class="btn btn-light me-2" @click="cancelUpdateSpec(index)">
                            Cancelar
                          </button>
                          <button type="button" class="btn btn-primary" @click="updateSpec(index)">
                            Salvar
                          </button>
                        </div>
                      </div>
                    </div>

                  </div>

                </div>

              </div>
            </div>
          </form>
        </div>
      </div>

      <ModalProfSpec :professional="this.selectedProf"/>
      <ModalProfPlacePayMethod :professional="this.selectedProf"/>
      <ModalProfPlaceInsurance :professional="this.selectedProf"/>
    </div>
  </div>
</template>

<script>
import http from "../../helpers/http";
import Alert from "../../helpers/alert";
import InviteProfessional from "./InviteProfessional";
import {mask} from "vue-the-mask";
import ModalProfSpec from "./ModalProfSpec";
import ModalProfPlacePayMethod from "./ModalProfPlacePayMethod";
import ModalProfPlaceInsurance from "./ModalProfPlaceInsurance";
import Permission from '../../helpers/permission';
import Toolbar from "../../components/layouts/auth/Toolbar.vue";

export default {
  name: "Professionals",
  directives: {
    mask,
  },
  components: {
    ModalProfSpec,
    ModalProfPlacePayMethod,
    ModalProfPlaceInsurance,
    InviteProfessional,
      Toolbar,
  },
  data() {
    return {
      search: '',
      professionals: [],
      selectedProf: '',
      formEdit: {
        id_prof: '',
        prof_name: '',
        prof_email: '',
        prof_phone: '',
        prof_cpf: '',
        active: true,
      },
      formStore: {
        id_prof: '',
        prof_name: '',
        prof_email: '',
        prof_phone: '',
        prof_cpf: '',
        active: true,
        id_place_owner: '',
      },
      orig_interval_minutes: '',
      formSettings: {
        id_prof: '',
        id_place: '',
        interval_minutes: '',
        default_release_time_for: '',
        time_begin: '',
        time_end: '',
        show_phone: '',
        show_address: '',
        notify_on_new_schedule: '',
        min_patient_age: '',
        max_patient_age: '',
        active_prof_place: true,
        available_to_patient: true,
        update_sched_new_interval_minutes: false,
      },
      formSettingsErrors: [],
      formEditErrors: [],
      formStoreErrors: [],
      specialties: [],
      newSpecialties: [],
    }
  },
  computed: {
    place() {
      return this.$store.state.auth.place;
    },
    userAuth() {
      return this.$store.state.auth.user;
    },
    professionalsFiltered() {
      return this.professionals.filter(professional => {
        return professional.prof_name.toLowerCase().includes(this.search.toLowerCase())
      });
    }
  },
  mounted() {
    this.loadProfessionals();
  },
  methods: {
    date: moment,
    hasPermission(permission) {
      return Permission.has(permission);
    },
    onClickBtnInvite() {
      document.getElementById('tab-nav-invites').click();
      setTimeout(() => {
        bootstrap.Modal.getOrCreateInstance(document.getElementById('modal_store_invite')).toggle();
      }, 100);
    },
    onClickBtnSettings(professional) {
      this.orig_interval_minutes = professional.interval_minutes;
      this.formSettings = {
        id_prof: professional.id_prof,
        id_place: professional.id_place,
        interval_minutes: professional.interval_minutes,
        time_begin: professional.time_begin,
        time_end: professional.time_end,
        show_phone: professional.show_phone,
        show_address: professional.show_address,
        notify_on_new_schedule: professional.notify_on_new_schedule,
        default_release_time_for: professional.default_release_time_for,
        min_patient_age: professional.min_patient_age,
        max_patient_age: professional.max_patient_age,
        active_prof_place: professional.active_prof_place,
        available_to_patient: professional.available_to_patient,
      };

      this.loadSpecialties(professional.id_prof).then(() => {
        bootstrap.Modal.getOrCreateInstance(document.getElementById('modal_settings')).toggle();
      });

    },
    onClickBtnStore() {
      document.getElementById('tab-nav-professionals').click();
      this.formStore = {
        prof_name: '',
        prof_email: '',
        prof_phone: '',
        prof_cpf: '',
        active: true,
        id_place_owner: this.place.id_place,
      };
      bootstrap.Modal.getOrCreateInstance(document.getElementById('modal_store')).toggle();
    },
    onClickBtnEdit(professional) {
      this.formEdit = {
        id_prof: professional.id_prof,
        prof_name: professional.prof_name,
        prof_email: professional.prof_email,
        prof_phone: professional.prof_phone,
        prof_cpf: professional.prof_cpf,
        active: professional.active,
        id_place_owner: professional.id_place_owner,
      };
      bootstrap.Modal.getOrCreateInstance(document.getElementById('modal_edit')).toggle();
    },
    onClickBtnPaymethod(professional) {
      this.selectedProf = professional;
      bootstrap.Modal.getOrCreateInstance(document.getElementById('modal_prof_place_paymethod')).toggle();
    },
    onClickBtnInsurance(professional) {
      this.selectedProf = professional;
      bootstrap.Modal.getOrCreateInstance(document.getElementById('modal_prof_place_insurance')).toggle();
    },
    onClickBtnSpec(professional) {
      this.selectedProf = professional;
      bootstrap.Modal.getOrCreateInstance(document.getElementById('modal_prof_spec')).toggle();
    },
    loadProfessionals() {
      http.get(`/places/${this.place.id_place}/professionals?include_inactive=true`).then((response) => {
        this.professionals = response.data;
      });
    },
    loadSpecialties(profId) {
      return http.get(`/places/${this.place.id_place}/professionals/${profId}/specialties`).then((response) => {
        this.specialties = response.data;
        this.newSpecialties = this.specialties.map(item => ({...item}));
      });
    },
    submitFormStore() {
      http.post(`/places/${this.place.id_place}/professionals`, this.formStore).then(_ => {
        this.loadProfessionals();
        this.$store.dispatch('auth/loadProfessionals');

        Alert.toastSuccess("Profissional cadastrado com sucesso.");
        this.formStoreErrors = [];
        this.formStore = {
          id_prof: '',
          prof_name: '',
          prof_email: '',
          prof_phone: '',
          prof_cpf: '',
          active: true,
        };

        bootstrap.Modal.getInstance(document.getElementById('modal_store')).toggle();
      }).catch(e => {
        Alert.toastError(e.response.data.message ?? e.response.data);
        if (e.response.data.errors) {
          this.formStoreErrors = e.response.data.errors;
        }
      });
    },
    submitFormEdit() {
      http.put(`/places/${this.place.id_place}/professionals/${this.formEdit.id_prof}`, this.formEdit).then(_ => {
        this.loadProfessionals();
        this.$store.dispatch('auth/loadProfessionals');

        Alert.toastSuccess("Profissional atualizado com sucesso.");
        this.formEditErrors = [];
        this.formEdit = {
          id_prof: '',
          prof_name: '',
          prof_email: '',
          prof_phone: '',
          prof_cpf: '',
          active: true,
        };

        bootstrap.Modal.getInstance(document.getElementById('modal_edit')).toggle();
      }).catch(e => {
        Alert.toastError(e.response.data.message ?? e.response.data);
        if (e.response.data.errors) {
          this.formEditErrors = e.response.data.errors;
        }
      });
    },
    submitFormSettings() {
      const makeRequest = () => {
        http.put(`/places/${this.place.id_place}/professionals/${this.formSettings.id_prof}/settings`, this.formSettings).then(_ => {
          this.loadProfessionals();
          this.$store.dispatch('auth/loadProfessionals');

          Alert.toastSuccess("Configurações atualizadas com sucesso.");
          this.formSettingsErrors = [];
          this.formSettings = {
            id_prof: '',
            id_place: '',
            interval_minutes: '',
            time_begin: '',
            time_end: '',
            show_phone: '',
            show_address: '',
            notify_on_new_schedule: '',
            min_patient_age: '',
            max_patient_age: '',
            active_prof_place: true,
            available_to_patient: true,
          };

          bootstrap.Modal.getOrCreateInstance(document.getElementById('modal_settings')).toggle();
        }).catch(e => {
          Alert.toastError(e.response.data.message ?? e.response.data);
          if (e.response.data.errors) {
            this.formSettingsErrors = e.response.data.errors;
          }
        });
      }

      if (this.orig_interval_minutes !== this.formSettings.interval_minutes) {
        Swal.fire({
          title: 'Intervalo de atendimento',
          text: "Gostaria de ajustar o tempo médio de atendimento dos agendamentos futuros?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sim, ajustar!',
          cancelButtonText: 'Não',
          customClass: {
            confirmButton: "btn btn-success",
            cancelButton: 'btn btn-secondary'
          }
        }).then((result) => {
          if (result.isConfirmed) {
            this.formSettings.update_sched_new_interval_minutes = true;
          }

          makeRequest()
        });
      } else {
        makeRequest();
      }
    },
    remove(id) {
      Swal.fire({
        title: 'Heeyy',
        text: "Tem certeza que deseja remover?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, remover!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: 'btn btn-secondary'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          http.delete(`/places/${this.place.id_place}/professionals/${id}`).then(_ => {
            this.loadProfessionals();
            this.$store.dispatch('auth/loadProfessionals');

            Alert.toastSuccess("Profissional removido com sucesso.");
          }).catch(e => {
            Alert.toastError(e.response.data);
          })
        }
      });
    },
    cancelUpdateSpec(index) {
      this.newSpecialties[index] = {...this.specialties[index]};
    },
    updateSpec(index) {
      const makeRequest = () => {
        http.put(`/places/${this.place.id_place}/professionals/${this.formSettings.id_prof}/specialties/${this.newSpecialties[index].id_spec}`, this.newSpecialties[index]).then(_ => {
          this.loadSpecialties(this.formSettings.id_prof);
          Alert.toastSuccess("Especialidade atualizada com sucesso.");
        }).catch(e => {
          Alert.toastError(e.response.data.message ?? e.response.data);
        });
      }

      if (this.newSpecialties[index].interval_minutes !== this.specialties[index].interval_minutes) {
        Swal.fire({
          title: 'Intervalo de atendimento',
          text: "Gostaria de ajustar o tempo médio de atendimento dos agendamentos futuros?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sim, ajustar!',
          cancelButtonText: 'Não',
          customClass: {
            confirmButton: "btn btn-success",
            cancelButton: 'btn btn-secondary'
          }
        }).then((result) => {
          if (result.isConfirmed) {
            this.newSpecialties[index].update_sched_new_interval_minutes = true;
          }

          makeRequest()
        });
      } else {
        makeRequest();
      }
    },
  }
}
</script>

<style scoped>

</style>