<template>
    <div class="table-responsive">
        <table class="table table-hover table-bordered">
            <thead>
            <tr class="text-center">
                <th scope="col">#</th>
                <th scope="col">Data</th>
                <th scope="col">Profissional</th>
                <th scope="col">Especialidade</th>
                <th scope="col">Forma de pagamento</th>
                <th scope="col">Status</th>
                <th scope="col">Celular</th>
                <th scope="col" v-if="showBtnEdit">Ações</th>
            </tr>
            </thead>
            <tbody :key="schedule.id_sched" v-for="schedule in schedules" class="text-center">
                <tr>
                    <td>
                        <button class="btn btn-sm btn-outline btn-outline-secondary"
                                type="button" data-bs-toggle="collapse"
                                :data-bs-target="'#subTable' + schedule.id_sched" aria-expanded="false"
                                aria-controls="collapseExample">
                            <i class="bi bi-plus-circle"></i>
                        </button>
                    </td>
                    <td>
                        {{this.date(schedule.schedule_date_time).format('DD/MM/YYYY HH:mm')}}
                    </td>
                    <td>{{schedule.prof_name}}</td>
                    <td>
                        {{schedule.kind_name}} <br>
                        {{schedule.spec_name}}
                    </td>
                    <td>
                        {{schedule.paymeth_name}}
                        <span v-if="schedule.insurance_name != null">
                            <br>
                            {{schedule.insurance_name}}
                        </span>
                    </td>
                    <td>
                        <span class="badge" :style="{backgroundColor: schedule.sched_status_color}">
                            {{schedule.status}}
                        </span>
                    </td>
                    <td>{{maskIt(schedule.sched_patient_phone, '(##) #####-####')}}</td>
                    <td v-if="showBtnEdit">
                        <button class="btn btn-icon btn-outline btn-outline-default me-1" type="button"
                                title="Agendamentos"
                                @click="onClickBtnEditSchedule(schedule)">
                            <i class="bi bi-person-lines-fill"></i>
                        </button>
                    </td>
                </tr>
                <tr>
                    <td colspan="12" class="p-0">
                        <div :id="'subTable' + schedule.id_sched" class="collapse">
                            <hr>
                            <h4 class="text-center">Histórico de ações</h4>
                            <hr>
                            <table class="table table-bordered border-primary">
                                <thead>
                                <tr>
                                    <th>Data/Hora</th>
                                    <th>Usuário</th>
                                    <th>Ação</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-if="schedule.sched_ended_user_name">
                                    <td>{{schedule.sched_end_br}}</td>
                                    <td>{{schedule.sched_ended_user_name}}</td>
                                    <td>Finalizou o atendimento</td>
                                </tr>
                                <tr v-if="schedule.sched_started_user_name">
                                    <td>{{schedule.sched_start_br}}</td>
                                    <td>{{schedule.sched_started_user_name}}</td>
                                    <td>Iniciou do atendimento</td>
                                </tr>
                                <tr v-if="schedule.sched_arrived_user_name">
                                    <td>{{schedule.sched_arrive_br}}</td>
                                    <td>{{schedule.sched_arrived_user_name}}</td>
                                    <td>Marcou chegada para atendimento</td>
                                </tr>
                                <tr v-if="schedule.sched_canceled_user_name">
                                    <td>{{schedule.canceled_at_br}}</td>
                                    <td>{{schedule.sched_canceled_user_name}}</td>
                                    <td>Cancelou o agendamento</td>
                                </tr>
                                <tr v-if="schedule.sched_confirmed_user_name">
                                    <td>{{schedule.confirmed_at_br}}</td>
                                    <td>{{schedule.sched_confirmed_user_name}}</td>
                                    <td>Confirmou a consulta</td>
                                </tr>
                                <tr>
                                    <td>{{schedule.created_at_at_br}}</td>
                                    <td>{{schedule.sched_user_name}}</td>
                                    <td>Agendado {{schedule.sched_by === 'PL' ? 'no local de atendimento' : 'via internet'}}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import http from "../../helpers/http";
import strMask from "../../helpers/strMask";

export default {
    name: "PatientScheduleTable",
    props: ['showBtnEdit', 'patientId', 'uniqueKey'],
    emits: ['onClickBtnEditSchedule'],
    data() {
        return {
            schedules: [],
        }
    },
    computed: {
        place() {
            return this.$store.state.auth.place;
        },
    },
    watch: {
      'uniqueKey': {
        handler() {
          this.getScheduleFromPatient();
        },
        immediate: true,
        deep: true
      }
    },
    methods: {
        date: moment,
        maskIt(value, mask) {
            return strMask.it(value, mask);
        },
        getScheduleFromPatient() {
            if (!this.patientId)
                return;

            http.get(`/places/${this.place.id_place}/patients/${this.patientId}/schedules`).then((response) => {
                this.schedules = response.data;
            });
        },
        onClickBtnEditSchedule(schedule) {
           this.$emit('onClickBtnEditSchedule', schedule);
        }
    }
}
</script>

<style scoped>

</style>