<template>
  <div
      v-if="!hasPermission('List Availability') && !hasPermission('List AvailWeek') && !hasPermission('List Availability Block')">
    Você não tem permissão para acessar esta página!
  </div>
  <div v-else>
      <Toolbar />
      <div class="container-xxl">
        <div class="card">
          <div class="mx-5">
            <div class="mt-5">
              <div class="row">
                <div class="col-md-3">
                  <label for="">Profissional</label>
                  <select class="form-select form-control-solid" aria-label="Selecionar Profissional"
                          v-model="currentProfessionalId"
                          @change="selectProfessional();">
                    <option :value="professional.id_prof" v-for="professional in professionals">
                      {{ professional.prof_name }}
                    </option>
                  </select>
                </div>
                <div class="col-md-3">
                  <label for="">Especialidade</label>
                  <select class="form-select form-control-solid" aria-label="Selecionar especialidade"
                          v-model="specId" @change="changeSpecId(specId)">
                    <option :value="specialty.id_spec" v-for="specialty in specialties">
                      {{ specialty.spec_name }}
                    </option>
                  </select>
                </div>
                <div class="col-md-3">
                  <div class="row form-check form-switch"
                       v-if="!specialties.find(spec => spec.id_spec === specId)?.kind_exam??false">
                    <label for="release_for">Liberar Horário para</label>
                    <select class="form-select form-control-solid" aria-label="Liberar Horário para"
                            :disabled="lastKindExam"
                            id="release_for" @change="refresh=true;" v-model="releaseFor">
                      <option value="B">Teleconsulta e presencial</option>
                      <option value="F">Presencial</option>
                      <option value="O">Teleconsulta</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="col-md-12 mt-5 ms-4">
                    <div class="row form-check form-switch"
                         v-if="!specialties.find(spec => spec.id_spec === specId)?.kind_exam??false">
                      <label class="form-check-label" for="">Permite Retorno?</label>
                      <input class="form-check-input" type="checkbox" role="switch"
                             :disabled="lastKindExam"
                             v-model="allowReturn" @change="refresh=true;">
                    </div>
                  </div>
                </div>
                <div class="col-md-1">
                  <button class="btn btn-flex btn-primary m-1" type="button"
                          title="Agendamentos" @click="this.$router.push('/schedules');">
                    <i class="bi bi-calendar-week"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">

            <ul class="nav nav-tabs mb-6 border-4 fw-bold">
              <li class="nav-item" v-if="hasPermission('List Availability')">
                <a class="nav-link active" data-bs-toggle="tab" href="#tab-time" @click="refresh=true;">Horário
                  Individual</a>
              </li>
              <li class="nav-item" v-if="hasPermission('List AvailWeek')">
                <a class="nav-link" data-bs-toggle="tab" href="#tab-week">Dia da Semana</a>
              </li>
              <li class="nav-item" v-if="hasPermission('List Availability Block')">
                <a class="nav-link" data-bs-toggle="tab" href="#tab-unavailability">Indisponibilidade</a>
              </li>
            </ul>

            <div class="tab-content w-100">
              <div class="tab-pane fade show active" id="tab-time" role="tabpanel">
                <AvailTime
                    :professional="professional"
                    :spec-id="specId"
                    :allow-return="allowReturn"
                    :release-for="releaseFor"
                    :refresh="refresh"
                    @onRefreshed="refresh=false">
                </AvailTime>
              </div>
              <div class="tab-pane fade" id="tab-week" role="tabpanel">
                <AvailWeek
                    :professional-id="currentProfessionalId"
                    :spec-id="specId"
                    :allow-return="allowReturn"
                    :release-for="releaseFor"
                    :refresh="refresh"
                    :interval-minutes="specialty?.interval_minutes??professional?.interval_minutes"
                    :specialties="specialties"
                    @onRefreshed="refresh=false">
                </AvailWeek>
              </div>
              <div class="tab-pane fade" id="tab-unavailability" role="tabpanel">
                <Unavailability
                    :current-professional-id="currentProfessionalId">
                </Unavailability>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import {mask} from "vue-the-mask";
import http from "../../../helpers/http";
import AvailWeek from "./tab/AvailWeek.vue";
import AvailTime from "./tab/AvailTime.vue";
import Unavailability from "./tab/Unavailability.vue";
import Permission from "../../../helpers/permission";

export default {
  name: "ReleaseTimes",
  components: {
    Unavailability,
    AvailWeek,
    AvailTime,
  },
  directives: {
    mask,
  },
  data() {
    return {
      refresh: false,
      releaseFor: 'F',
      allowReturn: true,
      specId: '',
      specialties: [],
      professional: {},
      specialty: {},
      currentProfessionalId: '',
      lastKindExam: true,
    }
  },
  computed: {
    place() {
      return this.$store.state.auth.place;
    },
    professionals() {
      return this.$store.state.auth.professionals;
    },
    userAuth() {
      return this.$store.state.auth.user;
    },
  },
  mounted() {
    if (this.professionals.length > 0) {
      this.currentProfessionalId = this.professionals.some(p => p.id_prof === this.userAuth?.id_prof) ? this.userAuth.id_prof : this.professionals[0].id_prof;
      this.selectProfessional();
    }
  },
  methods: {
    date: moment,
    hasPermission(permission) {
      return Permission.has(permission);
    },
    selectProfessional() {
      let professional = this.professionals.find(p => p.id_prof === this.currentProfessionalId);
      this.professional = professional;
      this.releaseFor = this.lastKindExam ? 'F' : professional?.default_release_time_for;
      this.loadSpecialties();
    },
    loadSpecialties() {
      http.get(`/places/${this.place.id_place}/professionals/${this.currentProfessionalId}/specialties`).then((response) => {
        this.specialties = response.data;
        if (this.specialties.length > 0) {
          this.specId = this.specialties[0].id_spec;
          this.lastKindExam = this.specialties[0].kind_exam;
        }
        this.refresh = true;
      });
    },
    isMobile() {
      return window.matchMedia('(max-width: 700px)').matches;
    },
    changeSpecId(specId) {
      let kindExam = this.specialties.find(spec => spec.id_spec === specId)?.kind_exam;
      if (kindExam !== this.lastKindExam) {
        this.lastKindExam = kindExam;
        this.releaseFor = kindExam ? 'F' : this.professional?.default_release_time_for;
        this.allowReturn = !kindExam;
      }
      console.log(this.lastKindExam);
      this.specialty = this.specialties.find(spec => spec.id_spec === specId)??{};
      this.refresh = true;
    }
  }
}
</script>

<style scoped>

</style>